import iframeResizer from 'iframe-resizer/js/iframeResizer';
import StyleInjector from 'src/lib/style_injector';

export default class CrossietyTimeline {
  constructor(target) {
    this.target = target;
    this.style = new StyleInjector('crossiety-timeline', this.styleRules());
    this.wrapper = this.iframeWrapper();
    this.loader = this.loader();
    this.iframe = this.iframe();
  }

  static autoload = true;

  static setup = el => new this(el).domInsert();

  static WAIT_TIME = 8000;

  iframeWrapper() {
    const w = document.createElement('div');

    w.className = 'crossiety-timeline-wrapper';
    return w;
  }

  iframe() {
    const i = document.createElement('iframe');
    i.className = 'crossiety-timeline-iframe';
    i.frameBorder = 0;

    const timeout = setTimeout(() => {
      this.onTimeout();
    }, this.constructor.WAIT_TIME);

    i.addEventListener('load', () => {
      clearTimeout(timeout);
      this.initIframeResizer();
    });

    i.src = this.target.href;
    return i;
  }

  generateError() {
    this.wrapper.innerHTML = `
      <div style="padding:10px; border:1px solid #ccc;">
        <strong>Verbindungsfehler</strong> Verbindung zu Crossiety kann nicht hergestellt werden!
      </div>
    `;
  }

  onTimeout() {
    this.generateError();
  }

  show() {
    this.iframe.style.opacity = 1;
  }

  hide() {
    this.iframe.style.opacity = 0;
  }

  initIframeResizer() {
    iframeResizer({
      onInit: () => {
        this.hideLoader();
        this.show();
      },
      inPageLinks: true,
      bodyPadding: '16px',
    }, this.iframe);
  }

  loader() {
    const l = document.createElement('div');

    l.className = 'crossiety-timeline-loader';
    l.innerHTML = '<div></div>';
    return l;
  }

  showLoader() {
    this.loader.style.display = 'block';
  }

  hideLoader() {
    this.loader.style.display = 'none';
  }

  styleRules() {
    return `
      .crossiety-timeline-wrapper {
        position: relative;
        min-height: 100px;
      }
      .crossiety-timeline-iframe {
        width: 1px;
        min-width: 100%;
        opacity: 0;
        transition: opacity ease-in-out 0.4s;
        transition-delay: 0.4s;
      }
      .crossiety-timeline-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .crossiety-timeline-loader div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 32px;
        height: 32px;
        border: 2px solid #fff;
        border-radius: 50%;
        animation: crossiety-timeline-loader 1.5s cubic-bezier(0.5, 0.2, 0.3, 0.5) infinite;
        border-color: #777 transparent transparent transparent;
      }
      @keyframes crossiety-timeline-loader {
        from { transform: rotate(-45deg); }
        to {  transform: rotate(315deg); }
      }
    `;
  }

  insertWrapper() {
    this.target.parentNode.replaceChild(this.wrapper, this.target);
  }

  insertLoader() {
    this.wrapper.appendChild(this.loader);
  }

  insertIframe() {
    this.wrapper.appendChild(this.iframe);
  }

  insertStyle() {
    this.style.inject();
  }

  domInsert() {
    this.insertStyle();
    this.insertWrapper();
    this.insertLoader();
    this.insertIframe();
  }
}

if (CrossietyTimeline.autoload) {
  window.addEventListener('load', () => {
    document.querySelectorAll('a[data-crossiety="timeline"]')
      .forEach(item => CrossietyTimeline.setup(item));
  });
}
