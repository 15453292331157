class StyleInjector {
  constructor(id, rules) {
    this.id = `style-injector-${id}`;
    this.rules = rules;
  }

  static insert(id, rules) {
    return new this(id, rules).inject();
  }

  inject() {
    this.create().safelyInsert();
  }

  // Private methods

  create() {
    const s = document.createElement('style');
    s.type = 'text/css';
    s.id = this.id;
    s.appendChild(document.createTextNode(this.rules));
    this.el = s;
    return this;
  }

  safelyInsert() {
    !this.exists() && this.appendToHead();
  }

  appendToHead() {
    document.head.appendChild(this.el);
  }

  exists() {
    return !!document.getElementById(this.id);
  }
}

export default StyleInjector;
